<template>
    <div class="answerPage">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.go(-1)">
            <template #left class="left">
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span style="font-size:17px;font-weight:500;">课程答疑</span>
            </template>
        </van-nav-bar>

        <div class="title1">
            <span class="title1_sp1">*</span>
            <span class="title1_sp2">问题</span>
        </div>
        <div class="ask" v-html="ask">
            考试允许待纸和笔吗？
        </div>
        
        <div class="title2">
            <span class="title2_sp1">答疑</span>
        </div>
        <div class="answer" v-html="answer || '等待专家解答'">
            您好同学，考试允许带笔，考场给发草稿纸
        </div>
        
    </div>
</template>

<script>
import {myDaYi} from '@/api/selectClass.js'

export default {
    data() {
        return {
            // 路径参数
            // coursePackageId:'',
            // courseId:'',
            // chapterId:'',
            // classHourId:'',

            ask:'',
            answer:''

        }
    },
    created() {
        // if (this.$route.query.coursePackageId) this.coursePackageId = this.$route.query.coursePackageId
        // if (this.$route.query.courseId) this.courseId = this.$route.query.courseId
        // if (this.$route.query.chapterId) this.chapterId = this.$route.query.chapterId //暂时没用到章节id
        // if (this.$route.query.classHourId) this.classHourId = this.$route.query.classHourId
        this.ask = this.$route.query.ask
        this.answer = this.$route.query.answer
        this.myDaYiFn()
    },
    methods: {
        async myDaYiFn(){
            let data = new FormData()
            data.append('page',0)

            let res = await myDaYi(data)
            console.log('课程答疑---------',res)
        }

    },
}
</script>

<style lang="less" scoped>
.answerPage {
    height: 100vh;
    background-color: #f5f5f5;
    padding: 0 15px;
    position: relative;

    .title1{
        margin: 20px 0;
        .title1_sp1{
            font-size: 18px;
            color: #E02727;
        }
        .title1_sp2{
            font-size: 18px;
            font-weight: bold;
        }
    }
    .ask{
        background-color: #fff;
        padding: 14px;
        border-radius: 5px;
        font-size: 14px;
    }

    .title2{
        margin: 20px 0;
        .title2_sp1{
            font-size: 18px;
            font-weight: bold;
        }
    }
    .answer{
        background-color: #fff;
        padding: 14px;
        min-height: 60px;
        border-radius: 5px;
        font-size: 14px;
    }
    
}
</style>

